<template>
  <div id="Home">
    <Sidebar />
    <router-view/>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    Sidebar
  }
}
</script>
<style>
#Home{
  display: flex;
}
</style>
