<template>
<div id="AllUsers">
  <div></div>
  <p>Below are all of the Skip Source users</p>
  <p>There are {{all_users.length}} Skip Source users</p>

  <div class="search-table">
    <div class="input-group">
      <input placeholder="Search for a user" v-model="search_query" />
      <button class="trace-btn" @click="getData()">Search</button>
    </div>
  </div>

  <!-- <div class="users-table">
      <div class="user-row" v-for="(user, idx) in filterList" :key="idx">
      <div class="user-main-text">
        <p>
          <span class="id-pod"><strong>ID:
          <span class="user-id" @click="copyToClipboard(user.id)">
            {{user.id.substring(0, 4)}}...{{user.id.substring(user.id.length-4, user.id.length)}}</span>
          </strong></span>
            : {{user.username}} | {{user.email}}
        </p>
      </div>

      <router-link :to="{name: 'Overview', params:{id: user.id}}" class="primary-btn"> Visit Profile</router-link>
    </div>
  </div> -->
  <div class="flex flex-col w-10/12 mx-auto">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Time Added
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              </th>
              <th scope="col" class="relative px-6 py-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(user, idx) in filterList" :key="idx">
              <td class="px-6 py-4 whitespace-nowrap">

                <div class="flex items-center cursor-pointer" @click="copyToClipboard(user.id)">
                  <div class="flex-shrink-0 h-10 w-10">
                    <img class="h-10 w-10 rounded-full" :src="user.photo" alt="" />
                  </div>
                  <div class="ml-4">
                    <div class="text-sm font-medium text-gray-900">
                      {{ user.fname }} {{ user.lname }}
                    </div>
                    <div class="text-sm text-gray-500">
                      {{ user.email }}
                    </div>
                    <p :class="{'text-blue-300 text-sm': copiedId}" v-if="user.id == copiedId">User ID Copied!</p>
                  </div>
                </div>

              </td>
              <td class="px-6 py-4">
              </td>
              <td class="dates-created px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer">
                <span class="simp-date">{{ mixins.methods.relativeDate(user.created) }}</span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <router-link
                  class="text-blue-600 hover:text-blue-900"
                  :to="{name: 'UserProfile', params:{userID: user.id}}">
                    View Profile
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <PaginationBar
          @paginate="paginate"
          :total_results="total_results"
          :count_start="1"
          :count_end="users_list.length"
          /> -->
      </div>
    </div>
  </div>
</div>

  <div class="view-section">
    <router-link :to="{name: 'Overview'}" > View All</router-link>
  </div>

  <p>{{filterList.length}} results</p>
  <p :class="{'copied': copiedId}" class="user-id copied1">User ID Copied!</p>
</div>
</template>
<script>
import axios from 'axios'
// import {fb, db} from '@/_firebase/init'
import {mapState, mapMutations} from 'vuex'
import mixins from '@/mixins'
// import GridLoader from 'vue-spinner/src/GridLoader.vue'
// import DefaultDropdown from '@/components/layout/DefaultDropdown.vue'
export default{
  name:'AllUsers',
  data(){
    return{
      mixins: mixins,
      copiedId: false,
      search_query: '',
      current_select:'Today',
      date_range: ['Today', 'This Week', 'This Month', 'This Year'],
      all_users:[{id:'yoo', username: 'teser', email: 'test@email.com'}]
    }
  },
  methods:{
    copyToClipboard(user_id){
      const link = user_id
      navigator.clipboard.writeText(link);
      this.copiedId = true
      setTimeout(() =>{
        this.copiedId = false
      }, 1000);
      this.UPDATE_SELECTED_USER(user_id)
    },
    ...mapMutations(['UPDATE_USER_COUNT', 'UPDATE_SELECTED_USER']),
    async getUsers(){
      this.all_users = []
      axios.get('https://skipsource.skylineexecutive.com/admin/all_users'
      ).then((res)=>{
        console.log(res.data)
        this.all_users = res.data.results
      }).catch((err) =>{
        console.log(err)
      })
    },
  },
  computed:{
    ...mapState([]),
    filterList(){
      let chrono_list = this.all_users
      chrono_list.sort((a, b) => b.created - a.created)
       let filteredFirstNames = chrono_list.filter((x)=>{
          let results = x.fname.includes(this.search_query)
           return results
       })
       let filteredLastNames = chrono_list.filter((x)=>{
          let results = x.lname.includes(this.search_query)
           return results
       })
       let filteredEmail = chrono_list.filter((x)=>{
          let results = x.email.includes(this.search_query)
           return results
       })
       let filteredID = chrono_list.filter((x)=>{
          let results = x.id.includes(this.search_query)
           return results
       })
       let full_user_list = [...filteredFirstNames, ...filteredLastNames, ...filteredEmail, ...filteredID]
       let uniq = [...new Set(full_user_list)];
       return uniq
    }
  },
  created(){
    this.getUsers()
    // for(let i = 0; i < 9; i++){
    //   this.all_users.push({id:'yoo', username: 'teser', email: 'test@email.com'})
    // }
  }
}
</script>
<style scoped>
.view-section {
  text-align:center;
  width: 100%;
}
.view-section a{
  color: #00AEEF;
  text-decoration: none;
}
.search-table{
  display: flex;
  width: 100%;
  max-height: 3rem;
  align-items: flex-end;
  justify-content: flex-end;
}
.input-group {
  display: flex;
  width: 70%;
  justify-content: flex-end;
  align-items:center;
}
.input-group input{
  transition: 1s;
  width: 70%;
  padding: .3rem 1rem;
  border: 1px solid #aaa;
  border-radius: 10px;
}
.trace-btn{
  padding: .5rem 1.3rem;
  margin: .4rem;
  transition: 1s;
  color:#00AEEF;
  background-color: #fff;
  border: 1px solid #00AEEF;
  border-radius: 20px;
  cursor:pointer;
}
.copied1{
    display: none;
}
.copied{
  display: block;
}
.id-pod{
  border-radius: 5px;
  background-color: #dfdfdf;
  margin: .2rem;
}
.user-id{
  color:#00AEEF;
}
.user-id:hover{
  cursor:pointer;
}
.users-table{
  max-height: 80vh;
  overflow-y: auto;
}
.users-table::-webkit-scrollbar {  width: 7px;}
.users-table::-webkit-scrollbar-track {  background: #f1f1f1; }
.users-table::-webkit-scrollbar-thumb {  background: #888;  border-radius: 10px;}
.users-table::-webkit-scrollbar-thumb:hover {  background: #555; }
.user-row{
  min-width: 10rem;
  margin: .5rem auto;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  padding: 0rem;
  border-radius:10px;
}
.primary-btn{
  background-color: var(--link-light);
  color: #fff;
  text-decoration: none;
  transition: 1s;
}
.primary-btn:hover{
  opacity: .5;
}
.user-row a{display: flex; justify-content: flex-start; align-items:center; padding: 1.4rem;}
.user-row a img{width: 3rem; margin-right: .6rem;}
</style>
