<template>
  <div class="border-r-2 min-h-screen bg-white">
    <div class="w-full">
      <img class="w-2/3 mx-auto my-4" src="@/assets/logo.svg" />
    </div>

    <button v-for="(mu, idx) in menu"
      :key="idx" :class="{'bg-red-400 text-white p-3 rounded-lg': mu == current_tab}"
      class="w-9/12 flex items-center my-10 ml-5 p" @click="selectTab(mu)">

    <div class="w-8" v-if="mu != current_tab">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.34 469.34"><path d="M448,213.34H277.34A21.33,21.33,0,0,1,256,192V21.34A21.33,21.33,0,0,1,277.32,0H448a21.33,21.33,0,0,1,21.34,21.32V192A21.33,21.33,0,0,1,448,213.34ZM298.67,170.67h128v-128h-128Z" transform="translate(0 0)" style="fill:#555"/><path d="M362.67,469.34A106.67,106.67,0,1,1,469.34,362.67,106.67,106.67,0,0,1,362.67,469.34Zm0-170.67a64,64,0,1,0,64,64A64,64,0,0,0,362.67,298.67Z" transform="translate(0 0)" style="fill:#555"/><path d="M192,256H21.34A21.33,21.33,0,0,0,0,277.32V448a21.33,21.33,0,0,0,21.32,21.34H192A21.33,21.33,0,0,0,213.34,448V277.34A21.33,21.33,0,0,0,192,256ZM170.67,426.67h-128v-128h128Z" transform="translate(0 0)" style="fill:#555"/><path d="M192,213.34H21.34A21.33,21.33,0,0,1,0,192V21.34A21.33,21.33,0,0,1,21.32,0H192a21.33,21.33,0,0,1,21.34,21.32V192A21.33,21.33,0,0,1,192,213.34ZM42.67,170.67h128v-128h-128Z" transform="translate(0 0)" style="fill:#555"/></svg>
      </div>

      <div class="w-8" v-else>
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.34 469.34"><path d="M448,213.34H277.34A21.33,21.33,0,0,1,256,192V21.34A21.33,21.33,0,0,1,277.32,0H448a21.33,21.33,0,0,1,21.34,21.32V192A21.33,21.33,0,0,1,448,213.34ZM298.67,170.67h128v-128h-128Z" transform="translate(0 0)" style="fill:#fff"/><path d="M362.67,469.34A106.67,106.67,0,1,1,469.34,362.67,106.67,106.67,0,0,1,362.67,469.34Zm0-170.67a64,64,0,1,0,64,64A64,64,0,0,0,362.67,298.67Z" transform="translate(0 0)" style="fill:#fff"/><path d="M192,256H21.34A21.33,21.33,0,0,0,0,277.32V448a21.33,21.33,0,0,0,21.32,21.34H192A21.33,21.33,0,0,0,213.34,448V277.34A21.33,21.33,0,0,0,192,256ZM170.67,426.67h-128v-128h128Z" transform="translate(0 0)" style="fill:#fff"/><path d="M192,213.34H21.34A21.33,21.33,0,0,1,0,192V21.34A21.33,21.33,0,0,1,21.32,0H192a21.33,21.33,0,0,1,21.34,21.32V192A21.33,21.33,0,0,1,192,213.34ZM42.67,170.67h128v-128h-128Z" transform="translate(0 0)" style="fill:#fff"/></svg>
        </div>

      <p class="text-xl ml-3">{{mu.name}}</p>
    </button>
  </div>
</template>
<script>
export default{
  data(){
    return{
      current_tab: {},
      menu:[
        {name: 'Overview', activeTab: true},
        // {name: 'Skip Tracing', activeTab: false},
        {name: 'Users', activeTab: false},
        // {name: 'Products', activeTab: false},
        {name: 'Sales', activeTab: false},
        // {name: 'Analytics', activeTab: false},
      ]
    }
  },
  methods:{
    selectTab(mu){
      console.log(mu.name)
      this.current_tab = mu
      this.$router.push({name: mu.name})
    }
  },
  created(){
    this.current_tab = this.menu[0]
  }
}
</script>
