<template>
  <div class="max-w-sm w-full rounded material-card bg-white border-gray-200 border rounded-lg" >
    <div class="px-6 py-4">
      <div class="font-bold text-xl tracking-wide">{{title}}</div>
      <div class="text-gray-500 text-sm italic mb-3">{{description}}</div>
      <p class="text-gray-700 text-right text-base text-2xl font-bold">
        <span v-if="tag == 'currency'">$</span>{{(count).toLocaleString()}}
      </p>
    </div>
    <div class="mx-4 mt-2 mb-4">
      <a class="tracking-wider uppercase font-bold text-red-700 hover:bg-purple-100 rounded p-2 inline-block" href="#">View More</a>
    </div>
  </div>
</template>
<script>
export default{
  props:['title', 'count', 'tag', 'description'],

}
</script>
<style scoped>
.top-area{
  min-height: 15vh;
}
</style>
