<template>
  <div class="w-full">
    <div class=" w-full border-b">
      <h1 class="font-bold text-2xl ml-5">Users</h1>
    </div>

    <AllUsers />
  </div>
</template>
<script>
import AllUsers from '@/components/AllUsers.vue'
export default{
  name: 'Users',
  components:{
    AllUsers
  }
}
</script>
