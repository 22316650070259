import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Overview from '../views/dashboard/Overview.vue'
import Users from '../views/dashboard/users/Users.vue'
import Products from '../views/dashboard/products/Products.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: 'overview',
    children:[
      {
        path: '/overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: '/users',
        name: 'Users',
        component: Users
      },
      {
        path: '/users/:userID',
        name: 'UserProfile',
        component: () => import('../views/dashboard/users/UserProfile.vue'),
      },
      {
        path: '/products',
        name: 'Products',
        component: Products
      },
      {
        path: '/sales',
        name: 'Sales',
        component: () => import('../views/dashboard/sales/Sales.vue'),
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  // {
  //   path: '/skip-tracing',
  //   name: 'SkipTracing',
  //   component: SkipTracing
  // },
  // {
  //   path: '/analytics',
  //   name: 'Analytics',
  //   component: Analytics
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
