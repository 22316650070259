<template>
  <div class="w-full">
    <div class="top-area bg-red-400 flex items-center w-full">
      <h1 class="text-2xl text-white font-bold ml-8">Welcome to Skip Source Admin Dashboard</h1>
    </div>

    <div class="flex w-11/12 mx-auto items-center justify-evenly">
      <DataSummaryCard v-for="(sum ,idx) in summary_data" :key="idx"
        :title="sum.title" :count="sum.count" :tag="sum.tag"
        :description="sum.description"/>
    </div>

  </div>
</template>
<script>
import DataSummaryCard from '@/components/snippets/DataSummaryCard.vue'
// import { db } from '@/_firebase/init'
import axios from 'axios'

export default{
  components:{
    DataSummaryCard
  },
  data(){
    return{
      summary_data : []
    }
  },
  methods:{
    getGeneralData(){
        this.all_users = []
        axios.get('https://skipsource.skylineexecutive.com/admin/summary_data'
        ).then((res)=>{
          console.log(res.data)
          this.summary_data.push(
            {'title': 'Users' , 'count': res.data.results['total_users'], 'tag': 'nominal', 'description':'total amount of users signed up for skip tracing software'}
          )
          this.summary_data.push(
            {'title': 'Cashbalance Liquidity' , 'count': (res.data.results['cashbalance_liquidity']/100), 'tag': 'currency', 'description':'the amount of money users hold in their cash balance'}
          )
        }).catch((err) =>{
          console.log(err)
        })
    },
  },
  created(){
    this.getGeneralData()
  }
}
</script>
<style scoped>
.top-area{
  min-height: 12vh;
}
</style>
